<template>
  <div class="episode-page" v-if="this.$store.state.english.episodePage.title">
    <section class="episode-player-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3 mb-lg-0 mb-4">
            <img
                v-scroll-animation="'animate__zoomIn'"
                class="img-fluid"
                :src="this.$store.state.english.episodePage.image_full_path"
                alt="Episode Image"
            />
          </div>
          <div class="col-lg-9">
            <h2 class="episode-title" v-scroll-animation="'animate__slideInDown'">
              {{ this.$store.state.english.episodePage.title }}</h2>
            <audio-player v-scroll-animation="'animate__slideInDown'"
                          :mp3-link="this.$store.state.english.episodePage.main_mp3_url"/>
            <div class="episode-info">
              <div class="episode-actions" v-scroll-animation="'animate__slideInDown'">
                <div class="action-item bookmark-btn">
                  <font-awesome-icon icon="share-alt"/>
                </div>
                <div class="action-item bookmark-btn" v-if="$store.getters['user/getToken']"
                     v-bind:class="{'active':$store.state.podcast.singleEpisodePage.is_in_fav}"
                     @click="changeFavStatus($store.state.podcast.singleEpisodePage.is_in_fav)">
                  <font-awesome-icon class="active-icon" icon="heart"/>
                  <font-awesome-icon :icon="['far', 'heart']"/>
                </div>
                <!--                <div class="action-item bookmark-btn">-->
                <!--                  <font-awesome-icon class="active-icon" icon="bookmark"/>-->
                <!--                  <font-awesome-icon :icon="['far', 'bookmark']"/>-->
                <!--                </div>-->
              </div>
              <div class="episode-publish-info">
                <div class="publish-info-item" v-scroll-animation="'animate__slideInDown'">
                  <font-awesome-icon icon="calendar-week"/>
                  <p>{{ this.$store.state.english.episodePage.created_at }}</p>
                </div>
                <div class="publish-info-item" v-scroll-animation="'animate__slideInDown'">
                  <font-awesome-icon icon="clock"/>
                  <p>{{ this.$store.state.english.episodePage.audio_time }}</p>
                </div>
              </div>
            </div>
            <div class="episode-links">
              <listen-platform v-scroll-animation="'animate__zoomIn'" :link="name" :slug="value" v-bind:key="name"
                               v-for="(name,value) in this.$store.state.english.episodePage.listen_urls"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="episode-text-info-section">
      <div class="container">
        <div class="title-part">
          <h2 v-scroll-animation="'animate__slideInDown'">About the Episode</h2>
          <div v-scroll-animation="'animate__slideInDown'"
               v-html="this.$store.state.english.episodePage.short_description"/>
          <!--          <p v-scroll-animation="'animate__slideInDown'">-->
          <!--            {{ this.$store.state.english.episodePage.short_description }}-->
          <!--          </p>-->
        </div>

        <div class="info-group keywords">
          <h5 v-scroll-animation="'animate__slideInDown'">Keyword</h5>
          <router-link :to="{name:'EnSearch',params:{keyword:keyword.name,isPodcast:false}}" v-bind:key="keyword.name"
                       v-scroll-animation="'animate__slideInDown'"
                       v-for="keyword in this.$store.state.english.episodePage.tags_list">
            <span>{{ keyword.name }}</span>
          </router-link>
        </div>
        <div class="info-group" v-if="this.$store.state.english.episodePage.sources_list">
          <h5 v-scroll-animation="'animate__slideInDown'">Sources</h5>
          <a :href="keyword.url" target="_blank" v-bind:key="keyword.title"
             v-scroll-animation="'animate__slideInDown'"
             v-for="keyword in this.$store.state.english.episodePage.sources_list">
            <span>{{ keyword.title }}</span>
          </a>
        </div>
        <div class="more-episodes-part" v-if="this.$store.state.english.moreEpisodes.length>0">
          <h3 v-scroll-animation="'animate__slideInDown'">More Episodes</h3>
          <div class="episodes-container">
            <swiper
                dir="ltr"
                ref="mySwiper"
                class="episodes-slider"
                :options="swiperOptions"
            >
              <swiper-slide v-bind:key="episode.id"
                            v-scroll-animation="'animate__zoomIn'"
                            v-for="episode in this.$store.state.english.moreEpisodes">
                <div @click="getNewEpisode(episode.id)" class="episode-item">
                  <div class="flip-card-inner">
                    <div class="frontSide">
                      <img :src="episode.image_full_path" alt="Program Image">
                    </div>
                    <div class="backSide">
                      <h4>{{ episode.title }}</h4>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ListenPlatform from "@/components/smallComponents/listenPlatformItem";
import AudioPlayer from "@/components/audioPlayer/audioPlayer";

export default {
  name: "EnEpisode",
  components: {ListenPlatform, AudioPlayer},
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 3,
        spaceBetween: 50,
        loop: false,
        centeredSlides: true,
        speed: 500,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          220: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  methods: {
    getNewEpisode(id, isFirstTime) {
      this.$store.dispatch("loading/show");
      this.$store
          .dispatch("english/getEpisodeDetails", {
            part_id: id,
          })
          .then(() => {
            if (!isFirstTime) {
              this.$router.push({name: 'EnEpisode', params: {id: id}});
            }
            this.$store.dispatch("loading/hide");

            navigator.mediaSession.metadata = new MediaMetadata({
              title: this.$store.state.english.episodePage.title,
              artist: 'تنوين ميديا',
              album: this.$store.state.english.episodePage.podcast_program.title,
              artwork: [{src: this.$store.state.english.episodePage.image_full_path}]
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
    },
  },
  created() {
    this.getNewEpisode(this.$route.params.id, true);
  },
}
</script>

<style lang="scss">
.episode-page {
  padding: 5em 0 4em 0;
  background-color: #fbfcfc;

  .episode-player-section {
    margin-bottom: 3em;

    .img-fluid {
      border-radius: 8px;
      box-shadow: 0 0 12px rgba(#000000, 0.1);
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

    .episode-title {
      font-weight: 700;
      color: $myViolet;
    }

    .episode-info {
      margin-top: 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .episode-actions {
        display: flex;
        align-items: center;

        .action-item {
          margin-inline-end: 0.75em;
          cursor: pointer;

          &:last-of-type {
            margin-inline-end: 0;
          }

          svg {
            font-size: 1.5rem;
            color: $myBlue;

            &.active-icon {
              display: none;
            }
          }

          &.active {
            svg {
              display: none;

              &.active-icon {
                display: block;
                color: $myYellow;
              }
            }
          }
        }
      }

      .episode-publish-info {
        display: flex;
        align-items: center;

        .publish-info-item {
          display: flex;
          align-items: center;
          margin-inline-end: 1.5em;

          &:last-of-type {
            margin-inline-end: 0;
          }

          svg {
            margin-inline-end: 0.5em;
            color: $myViolet;
            font-size: 1.35rem;
          }

          p {
            margin-bottom: 0;
            color: $myBlue;
            font-weight: 700;
            font-size: 1.1rem;
          }
        }
      }
    }

    .episode-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1.3em;

      .link-item {
        display: inline-block;
        color: $myBlue;
        font-weight: 700;
        margin-inline-end: 0.7em;
        font-size: 0.95rem;

        &:last-of-type {
          margin-inline-end: 0em;
        }

        img {
          width: 2.5em;
          height: 1.5em;
          object-fit: contain;
          object-position: center;
          margin-inline-end: 0.35em;
        }
      }
    }
  }

  .episode-text-info-section {
    margin-top: 5em;

    .container {
      padding: 3.5em 2em;
      background-color: #f5f6f7;
    }

    .title-part {
      margin-bottom: 2em;

      h2 {
        font-weight: 700;
      }

      p {
        font-size: 1.1rem;
      }
    }

    .info-group {
      margin-bottom: 2em;

      &.keywords {
        h5 {
          margin-bottom: 0.5em;
        }

        a {
          display: inline-block;
          margin-inline-end: 0.5em;
          border: 1px solid $myBlue;
          border-radius: 1em;
          padding: 0.05em 1em;
          transition: 0.3s ease-in-out;

          &:hover {
            background-color: $myBlue;
            color: #ffffff;
          }
        }
      }

      h5 {
        color: $myViolet;
        font-weight: 700;
        font-size: 1.4rem;
      }

      p,
      a {
        color: $myBlue;
        display: block;
      }
    }

    .more-episodes-part {
      margin-top: 5em;

      h3 {
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 0em;
      }

      .episodes-container {
        overflow: hidden;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        padding: 3.5em 0;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
        .episode-item {
          display: block;
          cursor: pointer;
          height: 22em;
          perspective: 50em;

          .flip-card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;
            border-radius: 8px;
            box-shadow: 0 0 12px rgba(#000000, 0.05);
          }

          &:hover {
            .flip-card-inner {
              transform: rotateY(180deg);
            }
          }

          .frontSide {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            /* Style the front side (fallback if image is missing) */
            background-color: #bbb;
            color: black;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              border-radius: 8px;
            }

          }

          .backSide {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 3em 2.5em;
            background-color: #fff;
            transform: rotateY(180deg);
            border-radius: 8px;
          }

          img {
            border-radius: 8px;
            width: 100%;
            margin-bottom: 0.75em;
            box-shadow: 0 0 12px rgba(#000000, 0.1);
          }

          h4 {
            color: $myViolet;
            font-weight: 700;
            font-size: 1.3rem;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .episode-page {
    .episode-player-section {
      .episode-title {
        text-align: center;
      }

      .episode-links {
        margin-top: 2em;
        justify-content: space-around;
        flex-wrap: wrap;

        .link-item {
          margin-bottom: 1em;
        }
      }
    }
  }
}

</style>