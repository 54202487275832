<template>
  <div class="contactUs-page" v-if="this.$store.state.english.contactPage">
    <div class="container px-lg-5 px-3">
      <div class="title-part">
        <h1 v-scroll-animation="'animate__fadeInDown'">{{ this.$store.state.english.contactPage.contact_us_title }}</h1>
        <img v-scroll-animation="'animate__fadeInDown'" src="@/assets/images/contactus-icon.png" alt="Contact Icon"/>
      </div>
      <hr v-scroll-animation="'animate__fadeInDown'"/>
      <div class="section-content">
        <div class="row justify-content-between">
          <div class="col-lg-5 mb-lg-0 mb-5">
            <div class="description" v-scroll-animation="'animate__fadeInDown'"
                 v-html="this.$store.state.english.contactPage.contact_us_text">
              <!--              <p  v-scroll-animation="'animate__fadeInDown'">-->
              <!--                {{ this.$store.state.english.contactPage.contact_us_text }}-->
              <!--              </p>-->
            </div>
            <div class="contactLinks"
                 v-if="this.$store.state.english.contactPage.contact_us_email
                 ||this.$store.state.english.contactPage.contact_us_phone">
              <a href="mailto:info@tanwenmedia.com" class="link-item"
                 v-scroll-animation="'animate__fadeInDown'"
                 v-if="this.$store.state.english.contactPage.contact_us_email">
                <font-awesome-icon icon="envelope"/>
                <p>{{ this.$store.state.english.contactPage.contact_us_email }}</p>
              </a>
              <a href="tel:+90000000000" class="link-item"
                 v-scroll-animation="'animate__fadeInDown'"
                 v-if="this.$store.state.english.contactPage.contact_us_phone">
                <font-awesome-icon icon="phone-alt"/>
                <p>{{ this.$store.state.english.contactPage.contact_us_phone }}</p>
              </a>
            </div>
            <p class="social-title" v-scroll-animation="'animate__fadeInDown'">
              {{ this.$store.state.english.contactPage.contact_us_follow_text }}</p>
            <div class="social-links">
              <a class="social-item" :href="social.url" target="_blank"
                 v-scroll-animation="'animate__fadeInDown'"
                 v-bind:key="social.id"
                 v-for="social in this.$store.state.english.contactPage.social_media">
                <font-awesome-icon :icon="['fab', social.icon]"/>
              </a>
            </div>
          </div>
          <div class="col-lg-5">
            <form method="post" class="contact-form-container" @submit="sendContactUsForm">
              <div class="form-group"
                   v-scroll-animation="'animate__fadeInDown'">
                <label for="name" class="form-label">Name</label>
                <input
                    v-model="formItems.name"
                    id="name"
                    class="input-group"
                    type="text"
                    placeholder="Your full name"
                    required
                />
              </div>
              <div class="form-group"
                   v-scroll-animation="'animate__fadeInDown'">
                <label for="email" class="form-label">Email</label>
                <input
                    v-model="formItems.email"
                    id="email"
                    class="input-group"
                    type="email"
                    placeholder="Your email"
                    required
                />
              </div>
              <div class="form-group"
                   v-scroll-animation="'animate__fadeInDown'">
                <label for="phone" class="form-label">Phone number</label>
                <input
                    v-model="formItems.phone"
                    id="phone"
                    class="input-group"
                    type="tel"
                    placeholder="Your phone number"
                    required
                />
              </div>
              <div class="form-group"
                   v-scroll-animation="'animate__fadeInDown'">
                <label for="subject" class="form-label">Subject</label>
                <input
                    v-model="formItems.subject"
                    id="subject"
                    class="input-group"
                    type="text"
                    placeholder="Subject of the message"
                    required
                />
              </div>
              <div class="form-group"
                   v-scroll-animation="'animate__fadeInDown'">
                <label for="message" class="form-label">Message</label>
                <textarea
                    v-model="formItems.message"
                    rows="4"
                    id="message"
                    placeholder="Text of the message"
                    required
                />
              </div>
              <div class="form-group"
                   v-scroll-animation="'animate__fadeInDown'">
                <button type="submit" @click="sendContactUsForm">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "enContact",
  data() {
    return {
      formItems: {
        name: '',
        email: '',
        subject: '',
        message: '',
        phone: '',
      },
    };
  },
  methods: {
    sendContactUsForm(e) {
      e.preventDefault();
      this.$store.dispatch("loading/show");
      this.$http.post("/contactus/submit", this.formItems)
          .then(() => {
            this.$store.dispatch("loading/hide");
            this.$swal({
              title: 'Success',
              icon: 'success',
              confirmButtonText: 'OK',
              text: 'Your message has been sent'
            });
            this.formItems.name = "";
            this.formItems.message = "";
            this.formItems.subject = "";
            this.formItems.email = "";
          }).catch(err => {
        this.$store.dispatch("loading/hide");
        this.$swal({
          title: 'Error',
          icon: 'error',
          confirmButtonText: 'OK',
          text: 'Please check all the fields before sending.'
        });
        console.log(err.message);
      })
    },
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store
        .dispatch("english/getContactPage")
        .then(() => {
          this.$store.dispatch("loading/hide");
        })
        .catch((err) => {
          console.log(err.message);
        });
  },
}
</script>

<style lang="scss" scoped>
.contactUs-page {
  background-color: #fbfcfc;
  padding: 4.5em 0 4em 0;

  .title-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.7em;

    h1 {
      color: $myBlue;
      font-weight: 700;
      margin-bottom: 0;
    }

    img {
      width: 4em;
      object-fit: contain;
    }
  }

  .section-content {
    .description {
      margin-bottom: 2.5em;

      p {
        margin-bottom: 0.5em;
      }
    }

    .contactLinks {
      margin-bottom: 2.5em;

      .link-item {
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        &:hover {
          svg,
          p {
            color: $myYellow;
          }
        }

        svg {
          font-size: 1.6rem;
          color: $myBlue;
          transition: 0.3s ease-in-out;
          margin-inline-end: 0.5em;
        }

        p {
          margin-bottom: 0;
          margin-inline-end: 0.75em;
          color: $myBlue;
          transition: 0.3s ease-in-out;
          direction: ltr;
        }
      }
    }

    .social-links {
      display: flex;
      flex-wrap: wrap;

      .social-title {
      }

      .social-item {
        svg {
          color: $myBlue;
          font-size: 1.6rem;
          margin-inline-end: 0.75em;
          transition: 0.3s ease-in-out;

          &:hover {
            color: $myYellow;
          }
        }
      }
    }
  }

  .contact-form-container {
    .form-group {
      margin-bottom: 1.5em;

      label {
        color: $myBlue;
      }

      input,
      textarea {
        display: block;
        border: none;
        background-color: #f5f6f7;
        padding: 0.5em 1.5em;
        border-radius: 8px;
        width: 100%;
        resize: none;
        outline: none !important;

        &:active {
          outline: none;
        }
      }

      button {
        background-color: $myYellow;
        color: white;
        border: none;
        border-radius: 1.5em;
        padding: 0.35em 1.5em;
        font-weight: 700;
        display: block;
        margin-left: 0;
        margin-right: auto;
        transition: 0.3s ease-in-out;

        &:hover {
          background-color: $myBlue;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .contactUs-page {
    .section-content {
      .description {
        p {
          text-align: center;
        }
      }

      .contactLinks {
        .link-item {
          justify-content: center;
        }
      }

      .social-title {
        text-align: center;
      }

      .social-links {
        justify-content: center;
        margin-bottom: 2em;
      }
    }

    .contact-form-container {
      .form-group {
        label {
          text-align: center;
          display: block;
        }

        button {
          margin-left: auto;
        }
      }
    }
  }
}
</style>