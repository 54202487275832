<template>
  <div class="about-page" v-if="this.$store.state.english.aboutPage">
    <section class="title-part">
      <div class="container">
        <div class="top-part">
          <h1 v-scroll-animation="'animate__fadeInDown'">
            {{ this.$store.state.english.aboutPage.about_us_title }}
          </h1>
          <img
              :src="
              this.$store.state.english.aboutPage.about_us_image_full_path
            "
              alt="About us icon"
              v-scroll-animation="'animate__fadeInDown'"
          />
        </div>
        <hr/>
        <div class="bottom-part" v-scroll-animation="'animate__fadeInDown'"
             v-html="this.$store.state.english.aboutPage.about_us_text ">
          <!--          <p-->
          <!--              class=""-->
          <!--              v-scroll-animation="'animate__fadeInDown'"-->
          <!--          >-->
          <!--            {{ this.$store.state.english.aboutPage.about_us_text }}-->
          <!--          </p>-->
        </div>
      </div>
    </section>
    <section class="stuff-section">
      <div class="container">
        <h1>Stuff Members</h1>
        <div class="row">
          <div class="col-lg-4 col-6" :key="item.id"
               v-for="item in this.$store.state.english.aboutPage.about_us_team_new">
            <div class="stuff-member">
              <div class="img-container">
                <img :src="item.image_full_path"
                     class="img-fluid" alt="Member image">
              </div>
              <div class="member-name">
                <h3>{{ item.name_en }}</h3>
              </div>
              <div class="member-position">
                <p>{{ item.job_title_en }}</p>
              </div>
              <div class="social-links">
                <a :href="social.url" target="_blank" :key="social.icon" v-for="social in item.social_media_array">
                  <font-awesome-icon :icon="['fab', social.icon]"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="ourTeam-section" v-if="this.$store.state.english.aboutPage.about_us_team.length>0">
      <div class="container">
        <h2>{{ this.$store.state.english.aboutPage.about_us_team_title }}</h2>
        <div class="team-slider">
          <swiper
              ref="mySwiper"
              :options="swiperOptions"
              class="team-real-slider"
              dir="ltr"
          >
            <swiper-slide v-bind:key="human.name"
                          v-for="human in this.$store.state.english.aboutPage.about_us_team">
              <div class="team-item">
                <div class="icon-container">
                  <font-awesome-icon icon="user" v-if="human.gender ==='mr'"/>
                  <font-awesome-icon icon="user" v-else/>
                  <!--                  <font-awesome-icon-->
                  <!--                      class="alt-icon"-->
                  <!--                      icon="user-tie"/>-->
                </div>

                <h4>{{ human.name }}</h4>
                <p>{{ human.job }}</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
<!--    <section class="partners-section" v-if="this.$store.state.english.aboutPage.about_us_partners">-->
<!--      <div class="container">-->
<!--        <h2 v-scroll-animation="'animate__fadeInRight'">-->
<!--          {{ this.$store.state.english.aboutPage.about_us_partners_title }}</h2>-->
<!--        <div class="partners-slider">-->
<!--          <swiper-->
<!--              v-scroll-animation="'animate__fadeInRight'"-->
<!--              dir="ltr"-->
<!--              ref="partnersSwiper"-->
<!--              class="partners-real-slider"-->
<!--              :options="partnersSwiperOptions"-->
<!--          >-->
<!--            <swiper-slide v-bind:key="item.url" v-for="item in this.$store.state.english.aboutPage.about_us_partners">-->
<!--              <a :href="item.url" target="_blank" class="partners-item">-->
<!--                <img-->
<!--                    :src="$store.state.generalStore.urlPrefixForImages+item.image"-->
<!--                    alt="Sound cloud Icon"-->
<!--                />-->
<!--                <p>{{ item.title }}</p>-->
<!--              </a>-->
<!--            </swiper-slide>-->
<!--          </swiper>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<script>
export default {
  name: "enAbout",
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 50,
        loop: false,
        centeredSlides: false,
        speed: 500,
        // autoplay: {
        //   delay: 5000,
        // },
        autoplay: false,
        breakpoints: {
          220: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
      partnersSwiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        loop: false,
        centeredSlides: false,
        centerInsufficientSlides: true,
        speed: 500,
        // autoplay: {
        //   delay: 5000,
        // },
        autoplay: false,
        breakpoints: {
          220: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          767: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        },
      },
    };
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store
        .dispatch("english/getAboutPage")
        .then(() => {
          this.$store.dispatch("loading/hide");
        })
        .catch((err) => {
          console.log(err.message);
        });
  },
}
</script>

<style lang="scss" scoped>
.about-page {
  background-color: #fbfcfc;

  .title-part {
    background-color: #f5f6f7;
    padding: 3.5em 0;

    .top-part {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        color: $myViolet;
        font-weight: 700;
      }

      img {
        height: 4em;
        filter: drop-shadow(0 0 12px rgba(#000000, 0.15));
      }
    }

    .bottom-part {
      p {
        text-align: start;
        font-size: 1.15rem;
      }
    }
  }

  .ourTeam-section {
    padding: 3.5em 0;

    h2 {
      font-weight: 700;
      font-size: 2.2rem;
    }

    .team-slider {
      overflow: hidden;

      .team-item {
        padding: 2em 0em;
        border-radius: 8px;
        box-shadow: 0 0 12px rgba(#000000, 0.05);
        margin: 2em;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.5s $bounceAnimation;

        .icon-container {
          position: relative;
          margin-bottom: 1em;
          height: 5rem;
          width: 100%;
          overflow: hidden;

          svg {
            font-size: 4.5rem;
            transition: 0.5s $bounceAnimation;
            color: $myBlue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &.alt-icon {
              top: -150%;
            }
          }
        }

        h4 {
          color: $myViolet;
          font-weight: 700;
        }

        p {
          color: $myBlue;
          font-weight: 700;
        }

        &:hover {
          //transform: scale(1.1);
          box-shadow: 0 0 15px rgba(#000000, 0.15);

          //.icon-container {
          //  svg {
          //    top: 150%;
          //
          //    &.alt-icon {
          //      top: 50%;
          //    }
          //  }
          //}
        }
      }
    }
  }

  .partners-section {
    padding-bottom: 4em;

    h2 {
      font-weight: 700;
      font-size: 2.2rem;
    }

    .partners-slider {
      overflow: hidden;
      padding: 2em 0;

      .partners-item {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 5em;
          height: 2.5em;
          object-fit: contain;
          object-position: center;
          margin-inline-end: 0.5em;
          mix-blend-mode: overlay;
        }

        p {
          margin-bottom: 0;
          font-size: 1.4rem;
          font-weight: 700;
          color: rgba(#000000, 0.6);
        }
      }
    }
  }

  .stuff-section {
    padding-top: 3em;


    h1 {
      font-weight: 700;
      font-size: 2.2rem;
    }

    .row {
      margin-top: 2em;
      justify-content: center;

      .col-lg-4 {
        display: flex;
        justify-content: center;
      }

      .stuff-member {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 0 12px rgba(#000000, 0.07);
        padding: 1em 0.5em;
        margin-bottom: 2.5em;
        width: 80%;
        transition: 0.3s ease-in-out;

        &:hover {
          transform: scale(1.025);
          box-shadow: 0px 4px 16px rgba(#000000, 0.15);
        }

        .img-container {
          width: 10em;
          max-width: 70%;
          aspect-ratio: 1/1;
          border-radius: 50%;
          overflow: hidden;
          margin-inline: auto;
          border: 2px solid $myBlue;
          margin-bottom: 1em;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .member-name {
          text-align: center;
          color: $myViolet;
          font-weight: 700;
        }

        .member-position {
          text-align: center;
          font-weight: 700;
          color: $myBlue;
        }

        .social-links {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          a {
            margin-inline-start: 0.75em;

            &:first-of-type {
              margin-inline-start: 0;
            }
          }

          svg {
            color: $myBlue;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .about-page{
    .stuff-section{
      .row{
        .stuff-member{
          width: 100%;
        }
      }
    }
  }
}
</style>