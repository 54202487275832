<template>
  <div class="english-podcast-page" v-if="this.$store.state.english.podcastPage">
    <section class="hero-section">
      <img class="img-fluid" :src="this.$store.state.english.podcastPage.main_cover_full_path" alt="Main Image"/>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
            fill="#fdfdfd"
            fill-opacity="1"
            d="M0,256L80,261.3C160,267,320,277,480,245.3C640,213,800,139,960,106.7C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </section>
    <section class="allPrograms-section" v-if="this.$store.state.english.podcastPage.programs_count>0">
      <div class="container">
        <h2>
          All Programs
        </h2>
        <div class="programs-slider-container">
          <swiper
              dir="ltr"
              ref="allProgramsSwiper"
              class="programs-slider"
              :options="programsSwiperOptions"
          >
            <swiper-slide :key="item.id" v-for="item in this.$store.state.english.podcastPage.programs"
                          v-scroll-animation="'animate__fadeInDown'"
            >
              <router-link
                  :to="{ name: 'EnProgram', params: { id: item.id } }"
                  class="program-item"
              >
                <div class="flip-card-inner">
                  <div class="frontSide">
                    <img src="https://via.placeholder.com/1000" alt="Program Image">
                  </div>
                  <div class="backSide">
                    <h4>{{ item.title }}</h4>
                    <p>
                      {{ item.short_description }}
                    </p>
                  </div>
                </div>
              </router-link>
            </swiper-slide>

          </swiper>
        </div>
      </div>
    </section>
    <section class="instagram-posts-section" v-if="this.$store.state.english.podcastPage.podcast_en_instagram.length>0">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mb-lg-0 mb-4"
               :key="$store.state.english.podcastPage.podcast_en_instagram.indexOf(item) + 'instagramPost'"
               v-for="item in this.$store.state.english.podcastPage.podcast_en_instagram"
               v-html="item">
            <!--            <a href="#">-->
            <!--              <img class="img-fluid" src="https://via.placeholder.com/600" alt="Instagram post"/>-->
            <!--            </a>-->
          </div>
          <!--          <div class="col-lg-4 col-md-6 col-12 mb-lg-0 mb-4">-->
          <!--            <a href="#">-->
          <!--              <img class="img-fluid" src="https://via.placeholder.com/600" alt="Instagram post"/>-->
          <!--            </a>-->
          <!--          </div>-->
          <!--          <div class="col-lg-4 col-md-6 col-12">-->
          <!--            <a href="#">-->
          <!--              <img class="img-fluid" src="https://via.placeholder.com/600" alt="Instagram post"/>-->
          <!--            </a>-->
          <!--          </div>-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "podcastEnglish",
  data() {
    return {
      programsSwiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 3,
        spaceBetween: 50,
        loop: false,
        centeredSlides: true,
        initialSlide: 1,
        speed: 500,
        autoplay: {
          delay: 5000,
        },
        // autoplay:false,
        breakpoints: {
          220: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
  created() {
    this.$store.dispatch('loading/show');
    this.$store.dispatch('english/getPodcastPage')
        .then(res => {
          this.$store.dispatch('loading/hide');
        })
        .catch(err => {
          this.$store.dispatch('loading/hide');
        });
  }
}
</script>

<style lang="scss" >
.english-podcast-page {
  padding-bottom: 3em;
  background-color: #fbfcfc;

  .hero-section {
    padding-top: 1.5em;
    position: relative;
    padding-bottom: 7em;
    background-color: #f5f6f7;


    img {
      position: relative;
      margin-inline: auto;
      z-index: 2;
      display: block;
      width: 85%;
      border-radius: 8px;
      box-shadow: 0 0 12px rgba(#000000, 0.15);
    }

    svg {
      position: absolute;
      bottom: 0em;
      left: 0;
      width: 100%;
      z-index: 1;
    }
  }

  .allPrograms-section {
    padding-top: 2em;
    position: relative;
    padding-bottom: 2em;
    z-index: 2;

    h2 {
      text-align: start;
    }

    .programs-slider-container {
      .programs-slider {
        overflow: hidden;
        padding: 3em 0.5em;

        .program-item {
          height: 22em;
          display: block;
          transition: 0.5s $bounceAnimation;
          perspective: 50em;

          .flip-card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;
            border-radius: 8px;
            box-shadow: 0 0 12px rgba(#000000, 0.05);
          }

          &:hover {
            .flip-card-inner {
              transform: rotateY(180deg);
            }
          }

          .frontSide {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            /* Style the front side (fallback if image is missing) */
            background-color: #bbb;
            color: black;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              border-radius: 8px;
            }

          }

          .backSide {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 3em 2.5em;
            background-color: #fff;
            transform: rotateY(180deg);
            border-radius: 8px;
          }


          h4 {
            color: $myViolet;
            text-align: center;
          }

          p {
            color: #424242;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .instagram-posts-section {
    padding: 3em 0;

    .col-12{
      border-radius: 8px;
      overflow: hidden;
      //div{
      //  width: 100% !important;
      //}
      .eapps-instagram-feed-posts-slider-nav{
        width: 60px !important;
      }
      .eapps-instagram-feed{
        width: 100% !important;
      }
    }

    a {
      display: block;

      img {
        border-radius: 8px;
        box-shadow: 0 0 12px rgba(#000000, 0.15);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .english-podcast-page {
    .hero-section {
      padding-top: 0;

      img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>